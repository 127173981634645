import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import bullionStats from "../assets/bullioStats.png";
import logo from "../assets/Logo.png";
import logo2 from "../assets/logo2.png";
import { Box, Fade, LinearProgress, Modal, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import "../App.css";
import moment from "moment-timezone";
import io from "socket.io-client";
import Carousel from "react-material-ui-carousel";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import LimitExceededModal from "./LimitExceededModal";
import SubscriptionExpiringSoon from "./SubscriptionExpired";
import SubscriptionExpired from "./SubscriptionExpired";
import { useMemo } from "react";
import { getEconomicNews } from "../sevice/EconomicNews";
import EconomicNewsModal from "./EconomicNewsModal";

let socket = Cookies.get("socketUrl")
  ? io(JSON.parse(Cookies.get("socketUrl")))
  : null;
const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "90dvh",
    margin: "auto",

    display: "flex",
    justifyContent: "space-between",
    padding: "2rem",
    boxSizing: "border-box",
    columnGap: "2rem",
    [theme.breakpoints.down("md")]: {
      height: "92dvh",
      padding: "2rem 2rem .75rem 2rem",
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "1.5rem 1.5rem .75rem 1.5rem",
      height: "93dvh",
    },
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "30%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "40%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "32.3%",
    },
  },
  logo: {
    height: "33%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      height: "25%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "23%",
    },
  },
  time: {
    width: "100%",
    height: "6%",
    border: "1px solid #796225",
    boxSizing: "border-box",
    borderRadius: "10px",
    backgroundColor: "#221F19",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "15%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "20%",
    },
  },
  banner: {
    height: "36%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #796225",
    boxSizing: "border-box",
    borderRadius: "15px",
    [theme.breakpoints.down("md")]: {
      height: "50%",
    },
  },
  bannerImg: {
    width: "100%",
    height: "29.7dvh",
    objectFit: "fill",
    overflow: "hidden",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      height: "17dvh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "14dvh",
    },
  },
  stat: {
    height: "15%",
    gridArea: "stat",
    backgroundColor: "#221F19",
    borderRadius: "10px",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #796225",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  bullionStatsImg: {
    width: "10vw",
    height: "auto",
  },
  rightPart: {
    gridArea: "rightPart",
    width: "68%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "58%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "66%",
    },
  },

  header: {
    height: "25%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      height: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "46%",
    },
  },

  spotRate: {
    border: "1px solid #796225",
    boxSizing: "border-box",
    display: "flex",
    color: "#16341B",
    gap: ".6rem",
    borderRadius: "15px",
    color: "#FFFFFF",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "0",
    },
  },
  spotRateColGold: {
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "53%",
    },
  },
  spotRateColSilver: {
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "45%",
      marginTop: "-3px",
    },
  },
  spotRateBoxGoldRow2Col1: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxGoldRow2Col2: {
    gridArea: "spotRateBoxGoldRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col1: {
    gridArea: "spotRateBoxSilverRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col2: {
    gridArea: "spotRateBoxSilverRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },

  logoImg: {
    width: "100%",
    alignItems: "center",
    height: "100%",
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  logoImgTab: {
    width: "100%",
    alignItems: "center",
    height: "100%",
    objectFit: "contain",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  imgBox: {
    width: "50%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
  },

  commoditieTable: {
    gridArea: "commoditieTable",
    height: "67.2%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "37%",
    },
  },
  table: {
    gridArea: "table",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#D9B450",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    borderRadius: "15px 15px 0px 0px",
    alignItems: "center",
    height: "17%",
    color: "#1F1D1D",
    border: "1px #796225 solid",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "83%",
    flexDirection: "column",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    fontSize: "2vw",
    width: "25%",
  },
  tableRow1: {
    flexBasis: 0,

    // maxHeight: "20%",
    flexGrow: 1,
    backgroundColor: "#221F19",
    display: "flex",
    borderBottom: "1px #796225 solid",
    borderRight: "1px #796225 solid",
    borderLeft: "1px #796225 solid",
  },
  statMd: {
    height: "15%",
    gridArea: "stat",
    backgroundColor: "#221F19",
    borderRadius: "10px",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "auto",
    border: "1px solid #796225",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      height: "13%",
    },
  },
  bullionStatsImgTab: {
    width: "14vw",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "25vw",
    },
  },
  updates: {
    color: "#fff",
    display: "flex",
    color: "#FFFFFF",
    height: "7dvh",
    marginBottom: "2rem",
    [theme.breakpoints.down("md")]: {
      height: "6dvh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "5dvh",
    },
  },

  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#221F19",
    width: "100%",
    borderTop: "1px solid #796225",
    borderBottom: "1px solid #796225",
  },

  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  // const divRef = useRef();
  // let navigate = useNavigate();
  // const dispatch = useDispatch();
  const [adminData, setAdminData] = useState();
  const classes = useStyles();

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [openBlocked, setOpenBlocked] = useState(false);
  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [openlimitExpierd, setOpenlimitExpierd] = useState(false);
  const [openSubscription, setOpenSubscription] = useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });

  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });

  const [render, setRender] = useState(true);
  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        console.log(data);
        setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });

      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );

        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;
        if (elapsedTime <= 0) {
          clearInterval(timerId);
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      });
    }
  };

  useEffect(() => {
    handelFindBanners();
    handleFindRatio();
    hadlefetchNews();
    handleFindSpread();
    handleFinsCommodities();
    handleFindAdminProfile();
  }, []);

  // useEffect(() => {
  //   handleloginChecking();
  // }, []);

  useEffect(() => {
    handleFindLiveValueTypeForDisplay();
  }, []);

  useEffect(() => {
    socket2.on("connect", () => {
      console.log("connect");
    });
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      // handleloginChecking();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      // handleLogout();
    });
    socket2.on("socketRerender", (data) => {
      Cookies.remove("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerExpire();
    }, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   handlecheckSubscriptionExpierd();
  // }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log("object");
  //     window.location.reload();
  //   }, 20 * 60 * 1000);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    handleFindSocketUrl();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  const handleFindSocketUrl = async () => {
    socket = io("https://customsocket.bullionview.com");
    Cookies.set(
      "socketUrl",
      JSON.stringify("https://customsocket.bullionview.com")
    );
    // const res = await getSocketUrl();
    // if (res) {
    //   socket = io(res?.socketURL);
    //   Cookies.set("socketUrl", JSON.stringify(res?.socketURL));
    // }
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);

  const buyersColor = "#266AAA";
  const sellersColor = "#C92136";

  const progressStyle = {
    backgroundColor: sellersColor,
  };

  const barStyle = {
    backgroundColor: buyersColor,
  };

  const formatTime = () => {
    return moment(new Date()).format("dddd DD MMM YYYY - hh:mm a");
  };

  // const handleloginChecking = async () => {
  //   const deviceId = Cookies.get("deviceId")
  //     ? JSON.parse(Cookies.get("deviceId"))
  //     : null;
  //   const res = await loginChecking(deviceId);
  //   if (res.status === false && res?.totalLoginCount <= res?.liveCounts) {
  //     setOpenlimitExpierd(true);
  //   } else if (res.status === false) {
  //     Cookies.set("tv", "");
  //     Cookies.set("deviceId", "");
  //     Cookies.remove("socketUrl");
  //     window.location.reload();
  //     dispatch({
  //       type: "LOGOUT",
  //     });
  //     navigate("/login");
  //   }
  // };

  const handleOpen = () => setOpenSubscription(true);
  const handleClose = () => setOpenSubscription(false);
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  ////////////Block//////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res?.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  async function triggerExpire() {
    const givenDate = new Date(adminData?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();

    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleOpen();
      }
    }
  }

  useEffect(() => {
    handleFindEconomicNews();
  }, []);
  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      console.log(objectBeforeOneHour);
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => a.priority - b.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => a.priority - b.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };
  return (
    <div
      style={{
        backgroundColor: `#181714`,
        width: "100%",
        height: "100dvh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className={(openlimitExpierd || openSubscriptionExpierd) && classes.blur}
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && (
            <Box
              sx={{
                position: "fixed",
                top: "0",
                left: "-125px",
                right: "0",
                bottom: "0",
                width: "895px",
                height: "531px",
                overflow: "hidden",
                zIndex: "99",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "10%",
                  transform: "rotate(-35deg)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#D9B450",
                  color: "#00000",
                  fontSize: "2vw",
                  fontWeight: "bold",
                  padding: "20px",
                  lineHeight: 1.5,
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Marquee delay="2" gradient={false}>
                  {remainingTime && (
                    <p style={{ marginLeft: "90px" }}>
                      Market closed! Opens on{" "}
                      {formatRemainingTime(remainingTime)}
                    </p>
                  )}
                </Marquee>
              </div>
            </Box>
          )}
          <Box className={classes.leftPart}>
            <Box className={classes.logo}>
              <Box className={classes.imgBox}>
                <img className={classes.logoImg} src={logo} alt="logo" />
                <img className={classes.logoImgTab} src={logo2} alt="logo" />
              </Box>
            </Box>
            <Box className={classes.time}>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: {
                    lg: "1.2vw",
                    md: "1.2vw",
                    sm: "2.7vw",
                    xs: "3.2vw",
                  },
                  letterSpacing: ".28vw",
                }}
              >
                {formatTime()}
              </Typography>
            </Box>
            <Box className={classes.banner}>
              <Carousel
                animation="fade" // Set the animation type to slide
                navButtonsAlwaysVisible={false} // Show navigation buttons always
                interval={10000}
                indicatorContainerProps={{
                  style: {
                    display: "none",
                  },
                }}
                sx={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "15px",
                  p: "1px",
                }}
                transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                autoPlay
                duration={2000}
              >
                {banners?.map((banner, i) => (
                  <img
                    // style={{
                    //   width: "100%",
                    //   height: { lg: "29.7vh", md: "29.7vh" },
                    //   objectFit: "cover",
                    //   // borderRadius: "10px",
                    // }}
                    className={classes.bannerImg}
                    src={banner.imageUrl}
                    alt="banner"
                  />
                ))}
              </Carousel>
            </Box>

            <Box className={classes.stat}>
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 5,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1.2vw" }}>BUYERS</Typography>
                  <Typography
                    sx={{
                      color: "#F8FBFF",
                      fontSize: "1.3vw",
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "#266AAA"
                          : "#C92136",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ fontSize: "1.2vw" }}>SELLERS</Typography>
                </Box>
                <Box sx={{ width: "90%", px: 4 }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1.3vw", color: "#266AAA" }}>
                    {ratio?.Buyers}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <a href="https://www.bullionstats.com/">
                      <img
                        className={classes.bullionStatsImg}
                        src={bullionStats}
                        alt="bullionStats"
                      />
                    </a>
                  </Box>
                  <Typography sx={{ fontSize: "1.3vw", color: "#C92136" }}>
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </>
            </Box>
          </Box>
          <Box className={classes.rightPart}>
            <Box className={classes.header}>
              <Box className={classes.spotRate}>
                <Box className={classes.spotRateColGold}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.4vw",
                        fontWeight: "bold",
                        flexBasis: 0,
                        flexGrow: 0.8,
                        flexShrink: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        flexBasis: 0,
                        flexGrow: 1,
                        flexShrink: 1,
                      }}
                    >
                      <Box
                        sx={{
                          color: "#383535",
                          background: "#FFFFFF",
                          px: "7px",
                          display: "inline-block",
                          width: "fit-content",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "1vw",
                              md: "1vw",
                              sm: "1.3vw",
                              xs: ".6rem",
                            },
                            fontWeight: "bold",
                          }}
                        >
                          $
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.4vw",
                            md: "1.4vw",
                            sm: "2vw",
                            xs: "3.5vw",
                          },
                          fontWeight: "bold",
                        }}
                      >
                        {displayBidOrBuy.bidOrBuy.toUpperCase()}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.2vw",
                            md: "1.2vw",
                            sm: "1.8vw",
                            xs: "3.2vw",
                          },
                        }}
                      >
                        oz
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        flexBasis: 0,
                        flexGrow: 1,
                        flexShrink: 1,
                      }}
                    >
                      <Box
                        sx={{
                          color: "#383535",
                          background: "#FFFFFF",
                          px: "7px",
                          display: "inline-block",
                          width: "fit-content",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "1vw",
                              md: "1vw",
                              sm: "1.3vw",
                              xs: ".6rem",
                            },
                            fontWeight: "bold",
                          }}
                        >
                          $
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.4vw",
                            md: "1.4vw",
                            sm: "2vw",
                            xs: "3.5vw",
                          },
                          fontWeight: "bold",
                        }}
                      >
                        {displayBidOrBuy.askOrSell.toUpperCase()}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.2vw",
                            md: "1.2vw",
                            sm: "1.8vw",
                            xs: "3.2vw",
                          },
                        }}
                      >
                        oz
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: "60%",
                      display: "flex",
                      // alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        flexBasis: 0,
                        flexGrow: 0.8,
                        flexShrink: 1,
                        display: "flex",
                        // flexDirection: "column",
                        // justifyContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "7vh",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.5vw",
                            md: "1.5vw",
                            sm: "2.3vw",
                            xs: "4.3vw",
                          },
                          fontWeight: "bold",
                          letterSpacing: "3px",
                          // height: "100%",
                          padding: "auto",
                          // m: "auto",
                        }}
                      >
                        GOLD
                        {/* <br />
                        <Typography
                          sx={{
                            fontSize: {
                              lg: ".9vw",
                              md: ".9vw",
                              sm: "1.3vw",
                              xs: "2.5vw",
                            },
                            fontWeight: "bold",
                            flexBasis: 0,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          SPOT RATE
                        </Typography> */}
                      </Typography>
                    </Box>
                    <Box className={classes.spotRateBoxGoldRow2Col1}>
                      <Box
                        sx={{
                          backgroundColor:
                            Number(gold?.cur?.bid).toFixed(2) <
                            Number(gold?.pre?.bid).toFixed(2)
                              ? "#C92136"
                              : Number(gold?.cur?.bid).toFixed(2) >
                                Number(gold?.pre?.bid).toFixed(2)
                              ? "#266AAA"
                              : "#221F19",
                          border: "1px solid #FFFFFF",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          width: "7.3vw",
                          height: "7dvh",
                          display: "flex",
                          alignItems: "center",
                          px: { lg: 1.5, md: 1.4, sm: 2.8, xs: 3 },
                          justifyContent: "center",
                          transition: "background-color .5s ease",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "2vw",
                              md: "2vw",
                              sm: "3vw",
                              xs: "1.1rem",
                            },
                            fontWeight: "bold",
                          }}
                        >
                          {(
                            Number(spread.goldBidSpread) +
                            Number(gold?.cur?.bid)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          // pl: 1,
                          mt: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            background: "#C92136",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: {
                                lg: "1vw",
                                md: "1vw",
                                sm: "1.5vw",
                                xs: "2.5vw",
                              },
                            }}
                          >
                            Low
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "1vw",
                              md: "1vw",
                              sm: "1.5vw",
                              xs: "2.5vw",
                            },
                          }}
                        >
                          {(
                            Number(spread.goldLowSpread) +
                            Number(gold?.cur?.lowPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.spotRateBoxGoldRow2Col2}>
                      <Box
                        sx={{
                          backgroundColor:
                            Number(gold?.cur?.ask).toFixed(2) <
                            Number(gold?.pre?.ask).toFixed(2)
                              ? "#C92136"
                              : Number(gold?.cur?.ask).toFixed(2) >
                                Number(gold?.pre?.ask).toFixed(2)
                              ? "#266AAA"
                              : "#221F19",
                          border: "1px solid #FFFFFF",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          width: "7.3vw",
                          height: "7dvh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          px: { lg: 1.5, md: 1.4, sm: 2.8, xs: 3 },
                          transition: "background-color .5s ease",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "2vw",
                              md: "2vw",
                              sm: "3vw",
                              xs: "1.1rem",
                            },
                            fontWeight: "bold",
                          }}
                        >
                          {(
                            Number(spread.goldAskSpread) +
                            Number(gold?.cur?.ask)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          mt: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            background: "#266AAA",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: {
                                lg: "1vw",
                                md: "1vw",
                                sm: "1.5vw",
                                xs: "2.5vw",
                              },
                            }}
                          >
                            High
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "1vw",
                              md: "1vw",
                              sm: "1.5vw",
                              xs: "2.5vw",
                            },
                          }}
                        >
                          {(
                            Number(spread.goldHighSpread) +
                            Number(gold?.cur?.highPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.spotRateColSilver}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.4vw",
                        fontWeight: "bold",
                        flexBasis: 0,
                        flexGrow: 0.8,
                        flexShrink: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        flexBasis: 0,
                        flexGrow: 1,
                        flexShrink: 1,
                      }}
                    >
                      <Box
                        sx={{
                          color: "#383535",
                          background: "#FFFFFF",
                          px: "7px",
                          display: "flex",
                          // width: "fit-content",
                          borderRadius: "4px",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "1vw",
                              md: "1vw",
                              sm: "1.3vw",
                              xs: ".6rem",
                            },
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          $
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.4vw",
                            md: "1.4vw",
                            sm: "2vw",
                            xs: "3.5vw",
                          },
                          fontWeight: "bold",
                        }}
                      >
                        {displayBidOrBuy.bidOrBuy.toUpperCase()}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.2vw",
                            md: "1.2vw",
                            sm: "1.8vw",
                            xs: "3.2vw",
                          },
                        }}
                      >
                        oz
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        flexBasis: 0,
                        flexGrow: 1,
                        flexShrink: 1,
                      }}
                    >
                      <Box
                        sx={{
                          color: "#383535",
                          background: "#FFFFFF",
                          px: "7px",
                          display: "inline-block",
                          width: "fit-content",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "1vw",
                              md: "1vw",
                              sm: "1.3vw",
                              xs: ".6rem",
                            },
                            fontWeight: "bold",
                          }}
                        >
                          $
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.4vw",
                            md: "1.4vw",
                            sm: "2vw",
                            xs: "3.5vw",
                          },
                          fontWeight: "bold",
                        }}
                      >
                        {displayBidOrBuy.askOrSell.toUpperCase()}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.2vw",
                            md: "1.2vw",
                            sm: "1.8vw",
                            xs: "3.2vw",
                          },
                        }}
                      >
                        oz
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: "60%",
                      display: "flex",
                      // alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        flexBasis: 0,
                        flexGrow: 0.8,
                        flexShrink: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: { sm: "7vh", xs: "4.5vh" },
                        // padding: "auto",
                        // height: "7vh",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            lg: "1.5vw",
                            md: "1.5vw",
                            sm: "2.0vw",
                            xs: "3.4vw",
                          },
                          fontWeight: "bold",
                          letterSpacing: "3px",
                          // maxHeight: "7dvh",
                          padding: "auto",
                        }}
                      >
                        SILVER
                        {/* <Typography
                          sx={{
                            fontSize: {
                              lg: ".9vw",
                              md: ".9vw",
                              sm: "1.4vw",
                              xs: "2.5vw",
                            },
                            flexBasis: 0,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          SPOT RATE
                        </Typography> */}
                      </Typography>
                    </Box>

                    <Box className={classes.spotRateBoxSilverRow2Col1}>
                      <Box
                        sx={{
                          backgroundColor:
                            Number(silver?.cur?.bid) < Number(silver?.pre?.bid)
                              ? "#C92136"
                              : Number(silver?.cur?.bid) >
                                Number(silver?.pre?.bid)
                              ? "#266AAA"
                              : "#221F19",
                          border: "1px solid #FFFFFF",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          px: { lg: 1.5, md: 1.4, sm: 2.8, xs: 3 },
                          width: "7.3vw",
                          height: "7dvh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transition: "background-color .5s ease",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "2vw",
                              md: "2vw",
                              sm: "3vw",
                              xs: "1.1rem",
                            },
                          }}
                        >
                          &nbsp;&nbsp;
                          {(
                            Number(spread.silverBidSpread) +
                            Number(silver?.cur?.bid)
                          ).toFixed(3)}
                          &nbsp;&nbsp;
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            background: "#C92136",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: {
                                lg: "1vw",
                                md: "1vw",
                                sm: "1.5vw",
                                xs: "2.5vw",
                              },
                              mx: "auto",
                            }}
                          >
                            Low
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "1vw",
                              md: "1vw",
                              sm: "1.5vw",
                              xs: "2.5vw",
                            },
                            mx: "auto",
                          }}
                        >
                          {(
                            Number(spread.silverLowSpread) +
                            Number(silver?.cur?.lowPrice)
                          ).toFixed(3)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className={classes.spotRateBoxSilverRow2Col2}>
                      <Box
                        sx={{
                          backgroundColor:
                            Number(silver?.cur?.ask) < Number(silver?.pre?.ask)
                              ? "#C92136"
                              : Number(silver?.cur?.ask) >
                                Number(silver?.pre?.ask)
                              ? "#266AAA"
                              : "#221F19",
                          border: "1px solid #FFFFFF",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          px: { lg: 1.5, md: 1.4, sm: 2.8, xs: 3 },
                          width: "7.3vw",
                          height: "7dvh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transition: "background-color .5s ease",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "2vw",
                              md: "2vw",
                              sm: "3vw",
                              xs: "1.1rem",
                            },
                          }}
                        >
                          &nbsp;&nbsp;
                          {(
                            Number(spread.silverAskSpread) +
                            Number(silver?.cur?.ask)
                          ).toFixed(3)}
                          &nbsp;&nbsp;
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            background: "#266AAA",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: {
                                lg: "1vw",
                                md: "1vw",
                                sm: "1.5vw",
                                xs: "2.5vw",
                              },
                              mx: "auto",
                            }}
                          >
                            High
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: {
                              lg: "1vw",
                              md: "1vw",
                              sm: "1.5vw",
                              xs: "2.5vw",
                            },
                            mx: "auto",
                          }}
                        >
                          {(
                            Number(spread.silverHighSpread) +
                            Number(silver?.cur?.highPrice)
                          ).toFixed(3)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={classes.commoditieTable}>
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "start",
                      fontSize: {
                        sm: "2.2vw",
                        xs: "2.8vw",
                      },
                      fontWeight: "bold",
                      paddingLeft: {
                        sm: "30px",
                        xs: "20px",
                      },
                    }}
                  >
                    COMMODITY
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: {
                        sm: "2.2vw",
                        xs: "2.8vw",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    WEIGHT
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: {
                        sm: "2.2vw",
                        xs: "2.8vw",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    BUY
                    <Typography
                      sx={{
                        fontSize: {
                          sm: ".9vw",
                          xs: "2vw",
                        },
                        mt: {
                          sm: 2.2,
                          // xs: 0.5,
                        },
                      }}
                    >
                      &nbsp;AED
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: {
                        sm: "2.2vw",
                        xs: "2.8vw",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    SELL
                    <Typography
                      sx={{
                        fontSize: {
                          sm: ".9vw",
                          xs: "2vw",
                        },
                        mt: {
                          sm: 2.2,
                          // xs: 0.5,
                        },
                      }}
                    >
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>

                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity?.commodity_title.split(" ");
                    return (
                      <Box
                        key={idx}
                        className={classes.tableRow1}
                        sx={{
                          // maxHeight: commodities.length > 6 ? "16.6%" : "",
                          borderRadius:
                            idx === commodities.length - 1 &&
                            "0px 0px 15px 15px",
                          minHeight: {
                            lg: "16.4%",
                            md: "16.3%",
                            sm: "19.4%",
                            xs: "19.2%",
                          },
                          // maxHeight: { lg: "auto", md: "auto", sm: "19.1%" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              sm: "1.3vw",
                              xs: "2vw",
                            },
                            fontWeight: "bold",
                            justifyContent: "left",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {words?.map((word, index) => {
                            if (index === 0) {
                              return (
                                <Typography
                                  key={index}
                                  sx={{
                                    fontSize: {
                                      sm: "2.2vw",
                                      xs: "2.8vw",
                                    },
                                    fontWeight: "bold",
                                    paddingLeft: {
                                      sm: "30px",
                                      xs: "20px",
                                    },
                                  }}
                                >
                                  {word.toUpperCase()}
                                </Typography>
                              );
                            } else {
                              return (
                                <Typography
                                  key={index}
                                  sx={{
                                    fontSize: {
                                      sm: "1vw",
                                      xs: "1.8vw",
                                    },
                                    marginTop: { sx: "0.2rem" },
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;{word.toUpperCase()}
                                </Typography>
                              );
                            }
                          })}
                          &nbsp;
                          {commodity.unitLabel === "TTB"
                            ? ""
                            : commodity.displayPurity}
                        </Typography>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            sx={{
                              paddingLeft: {
                                md: "5.5vw",
                                sm: "11vw",
                                xs: "10vw",
                              },
                              fontSize: {
                                sm: "2.2vw",
                                xs: "2.8vw",
                              },
                              fontWeight: "bold",
                            }}
                          >
                            {commodity.unit} {commodity.unitLabel}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            sx={{
                              // paddingLeft: "4.8vw",
                              paddingLeft: {
                                md: "4.8vw",
                                sm: "9vw",
                                xs: "8vw",
                              },
                              fontSize: {
                                sm: "2.2vw",
                                xs: "2.8vw",
                              },
                              fontWeight: "bold",
                              // paddingLeft: { lg: "4.5vw", md: "4.5vw" },
                            }}
                          >
                            {commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.bid
                                : gold?.cur?.bid,
                              commodity.commodity_title === "Silver"
                                ? spread.silverBidSpread
                                : spread.goldBidSpread,
                              commodity.buy_premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.buy_charge
                            )}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            sx={{
                              // paddingLeft: "4.1vw",
                              paddingLeft: {
                                md: "4.1vw",
                                sm: "8vw",
                                xs: "6vw",
                              },
                              fontSize: {
                                sm: "2.2vw",
                                xs: "2.8vw",
                              },
                              fontWeight: "bold",
                              // paddingLeft: { lg: "3.8vw", md: "3vw" },
                            }}
                          >
                            {commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.ask
                                : gold?.cur?.ask,
                              commodity.commodity_title === "Silver"
                                ? spread.silverAskSpread
                                : spread.goldAskSpread,
                              commodity.premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.charges
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box className={classes.statMd}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: { sm: 5, xs: 3 },
                  pt: { sm: 1, xs: 1.3 },
                  pb: { sm: 1 },

                  boxSizing: "border-box",
                }}
              >
                <Typography
                  sx={{ fontSize: { md: "1.2vw", sm: "1.5vw", xs: "2.2vw" } }}
                >
                  BUYERS
                </Typography>
                <Typography
                  sx={{
                    color: "#F8FBFF",
                    fontSize: { md: "1.3vw", sm: "1.6vw", xs: "2.5vw" },
                    color:
                      Number(ratio?.chgValue.replace("%", "")) >= 0
                        ? "#266AAA"
                        : "#C92136",
                  }}
                >
                  {ratio?.chgValue}
                </Typography>
                <Typography
                  sx={{ fontSize: { md: "1.2vw", sm: "1.5vw", xs: "2.2vw" } }}
                >
                  SELLERS
                </Typography>
              </Box>
              <Box sx={{ width: "90%", px: 3.5 }}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={progressStyle}
                  sx={{
                    "& .MuiLinearProgress-bar": barStyle,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: { sm: 7, xs: 4.5 },
                  py: 1,
                  boxSizing: "border-box",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "1.2vw", sm: "1.5vw", xs: "2vw" },
                    color: "#266AAA",
                  }}
                >
                  {ratio?.Buyers}
                </Typography>
                <Box sx={{ mt: { sm: -0.6, xs: 0 } }}>
                  <a href="https://www.bullionstats.com/">
                    <img
                      className={classes.bullionStatsImgTab}
                      src={bullionStats}
                      alt="bullionStats"
                    />
                  </a>
                </Box>
                <Typography
                  sx={{
                    fontSize: { md: "1.3vw", sm: "1.6vw", xs: "2vw" },
                    color: "#C92136",
                  }}
                >
                  {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.updates}>
          <Box className={classes.updatesContent} sx={{ minHeight: "100%" }}>
            <marquee behavior="" direction="">
              <Box sx={{ display: "flex" }}>
                {news?.map((item, index) => {
                  return (
                    <Box
                      key={item._id}
                      style={{
                        fontSize: "1.5vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {item.newsTitle}
                      </span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                      <span
                        style={{ paddingRight: "50px", paddingLeft: "50px" }}
                      >
                        {index === news.length - 1 ? "" : "|"}
                      </span>
                    </Box>
                  );
                })}
              </Box>
            </marquee>

            {/* <LogoutIcon
              sx={{ fontSize: "40px", color: "#FFFFF", cursor: "pointer" }}
              // onClick={handleLogout}
            /> */}
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openlimitExpierd}
        closeAfterTransition
      >
        <Fade in={openlimitExpierd}>
          <Box>
            <LimitExceededModal
            // logout={handleLogout}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscription}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openSubscription}>
          <Box>
            <SubscriptionExpiringSoon
              date={adminData?.package.expire_date}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Fade in={openSubscriptionExpierd}>
          <Box>
            <SubscriptionExpired
            // handleClose={handleLogout}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
      >
        <Box className={classes.newsStyle}>
          <EconomicNewsModal
            handleClose={handleCloseopenNews}
            curEconomicNews={curEconomicNews}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Home;
